.node-inner {
  width: 100%;
  position: relative;
}

.page-profile, .node-type-tutor-profile, .node-type-student-profile {
  #main {
    padding-top: 0;
  }
  .view-filters {

    padding-top: 0;

    input[type="text"] {
      width: 100%;
    }

    input, .form-submit, .form-select {
      height: 30px;
      padding: 10px 15px;
      display: inline-block;
      line-height: 0;
      margin-right: 10px;
    }

    .form-select {
      line-height: inherit;
      padding: 0 15px;
    }

    .form-submit {
      margin-top: 25px;
    }

    .ajax-progress {
      float: right;
      position: relative;
      top: 28px;
    }

  }

  .views-exposed-widget {
    padding-top: 0;
  }
}

.profile-left {
  position: relative;
  color: $white;
  padding: 20px;
  text-align: center;
  background: linear-gradient(rgba(6, 13, 64, 0.8), rgba(6, 13, 64, 0.8)), url('../../images/build/profile.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  z-index: 2;
  overflow: auto;

  @include media($tablet) {
    position: relative !important;
    top: 0 !important;

    &.is_stuck + div {
      display: none !important;
    }

  }

  @include media($mobile) {
    height: calc(100vh);
    position: fixed !important;
    top: -100vh !important;
    @include bre-transition();
    width: 100% !important;

    &.visible {
      top: 30px !important;
    }
  }

  @include media($overtablet) {
    max-width: 400px;
    float: left;
    width: 30%;
    height: calc(100vh - 135px);
    min-width: 300px;

  }

  .inner {
    position: relative;
    z-index: 2;
  }

  img {
    width: 110px;
    height: 110px;
    border-radius: 150px;
    overflow: hidden;
  }
  .counsellor-name {
    font-size: 14px;
    margin-top: 5px;
  }

  #profile-name {
    font-size: 21px;
    color: #A6A8BD;
    font-weight: 300;
    margin-bottom: 5px;
  }
  .skype-id {
    color: $yellow;
    font-size: 14px;
    height: 22px;
    display: inline-block;
    margin-top: -116px;

    .icon {
      font-size: 18px;
      position: relative;
      top: 5px;
    }
  }
  .profile-links {
    list-style-type: none;
    text-align: left;
    font-size: 15px;
    margin-top: 20px;
    display: inline-block;
    width: 100%;
    padding-left: 95px;

    .unread-notifications{
      display: inline-block;
      background: #F7C601;
      width: 15px;
      height: 15px;
      border-radius: 15px;
      text-align: center;
      color: #000;
      font-size: 10px;
      line-height: 1.4;
      position: relative;
      left: -9px;
      top: -2px;
    }

    a {
      color: $white;
      text-transform: uppercase;
      display: inline-block;
      padding: 5px 15px;
      margin: 10px 0;
      line-height: 1;
      border-left: 1px solid transparent;
      position: relative;

      &.active, &:hover {
        border-left: 1px solid $white;
      }
      &.active:after {
        content: '';
        position: absolute;
        width: 500px;
        border-bottom: 1px solid $white;
        left: -500px;
        top: 12px;
      }

    }

    @media(max-width: 1200px) {

      padding-left: 50px;

    }

  }

}

.node-type-student-profile, .node-type-tutor-profile {
  .profile-links a.info-link {
    border-left: 1px solid $white;

    &:after {
      content: '';
      position: absolute;
      width: 500px;
      border-bottom: 1px solid $white;
      left: -500px;
      top: 12px;
    }
  }

  .form-type-date-text #edit-field-entry-year-und-0-value .description {
    display: none;
  }
}

.profile-right {

  min-height: calc(100vh - 110px);
  background: $white;
  position: relative;
  z-index: 1;
  @include clearfix();

  @include media($overtablet) {
    margin-left: 400px;
    padding: 30px 140px;
  }

  @media(max-width: 1460px) {
    margin-left: 30%;
    padding: 30px 30px;
  }

  @include media($tablet) {
    margin-left: 0;
    padding: 60px 0 0;
  }

  .profile-fieldset, .view-empty {
    background: #F7F8F9;
    padding: 30px 75px;
    position: relative;
    overflow: hidden;
    clear: right;

    @include media($overtablet) {
      margin: 0 auto 30px;
    }
    @include media($tablet) {
      margin: 0 20px 20px;
      padding: 50px 15px 20px;
    }

    h3 {
      text-align: center;
      font-size: 20px;
      border-bottom: 2px dotted #C4C7CE;
      padding-bottom: 24px;
      margin-top: 0;
      margin-bottom: 30px;
      transition: all 0.2s ease-in-out;

      .fieldset-description {
        display: block;
        font-size: 15px;
        font-family: 'Lato', sans-serif;
        color: $purple-text;
        padding: 10px 0 0;
      }
    }

    h4 {
      padding-left: 50px;
      margin-bottom: -25px;

      @include media($tablet) {
        padding-left: 15px;
      }

      &.match-select-title {
        padding: 0;
      }

    }

    .edit-link {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    &.collapsed {
      h3 {
        margin-bottom: 0;
        padding-bottom: 0;
        border: 0;
      }
    }

    &.aptitude-tests {
      padding-bottom: 100px;
      a {
        color: $purple-text;
      }

      .text {
        margin-bottom: 40px;
      }

      .aptitude-tests-row {
        overflow: hidden;
        border-bottom: 1px solid $grey-med;
        padding: 10px 0;
        font-size: rem(14);

        div {
          float: left;

          &:first-child {
            width: 50%;
          }
          &:nth-child(2) {
            width: 20%;
          }
          &:last-child {
            width: 30%;
          }

        }

      }

      .aptitude-tests-row-head {
        font-weight: 500;
      }
    }

  }

  .profile-fieldset {
    .profile-fieldset-inner {
      display: none;
    }
  }

  .profile-collapse {
    background: none;
    border: 0;
    transform: rotate(-90deg);
    position: absolute;
    top: 29px;
    left: 70px;
    outline: none;
    transition: all 0.2s ease-in-out;
    font-size: 14px;

    &:hover {
      color: $yellow;
    }

    &.collapsed {
      transform: rotate(90deg);
    }

    svg {
      width: 14px;
      height: 14px;
    }
  }

  dl {
    overflow: hidden;
    padding: 25px 50px 0;

    @include media($tablet) {
      padding: 25px 15px;
    }

  }
  dt {

    display: block;
    clear: both;
    color: #4A4D63;
    padding-bottom: 15px;

    @include media($overmobile) {
      width: 200px;
      float: left;
    }

  }
  dd {
    display: block;
    margin: 0;
    font-weight: 400;
    color: #4A4D63;
    padding-bottom: 15px;

    @include media($overtablet) {
      float: right;
      width: calc(100% - 250px);
    }

    @include media($mobile) {
      border-bottom: 1px solid #cfcfcf;
      margin-bottom: 15px;
    }

  }

  .match-select-wrap {
    background: $white;
    padding: 5px 25px;

    h4 {
      font-weight: 700;
      font-size: 18px;
    }
  }

  .match-select-list {
    list-style-type: none;
    padding: 0;
    margin: 30px 0 10px;

    li {
      cursor: pointer;
      padding-left: 30px;
      position: relative;
      border-bottom: 1px solid $bg-grey-light;
      padding: 5px 0 5px 20px;

      &:last-child {
        border: 0;
      }

      &:after {
        content: '';
        width: 11px;
        height: 11px;
        border-radius: 11px;
        background: transparent;
        border: 2px solid $yellow;
        left: 0;
        top: 12px;
        position: absolute;
      }

      &.active {
        font-weight: 400;

        &:after {
          background: $yellow;
        }
      }

    }

    li[data-type="preferred"], li[data-type="available"] {

      &:after {
        border-color: #07104F;
      }

      &.active {
        &:after {
          background: #07104F;
        }
      }

    }

    li[data-type="less-preferred"] {

      &:after {
        border-color: #4a5079;
      }

      &.active {
        &:after {
          background: #4a5079;
        }
      }

    }

    li[data-type="not_available"] {

      &:after {
        border-color: #cfcfcf;
      }

      &.active {
        &:after {
          background: #cfcfcf;
        }
      }

    }

    .notes {
      font-size: 13px;
    }

  }

  .matched-tutors-inner {
    padding: 0 20px;

  }

  .matched-tutors {
    overflow: hidden;
    overflow-x: auto;
    position: relative;

    .matched-tutors-legend {
      position: absolute;
      left: 70px;
      top: 15px;

      .legend-title {
        font-size: 13px;
        font-weight: 700;
      }

      ul {

        margin: 0;
        padding: 0;
        font-size: 13px;
        list-style-type: none;
      }

      li {
        position: relative;
        padding-left: 20px;

        &:after {
          content: '';
          background: $yellow;
          width: 10px;
          height: 10px;
          border-radius: 10px;
          position: absolute;
          left: 0;
          top: 5px;
        }
      }
    }

    table {
      font-size: 14px;
      margin-bottom: 60px;
      width: 100%;
    }
    td, th {
      padding: 10px;

      &.center {
        text-align: center;
      }
    }
    tr:nth-child(2n) {
      background: $white;
    }

    td:first-child {
      position: relative;
      min-width: 125px;

      &:before {
        content: '';
        background: transparent;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        display: inline-block;
        margin-right: 5px;
      }

      &.first-booked {
        &:before {
          background: $yellow;
        }
      }

    }

    a {
      color: $purple-text;
      font-weight: 600;
    }

    .tutor {

      @media(min-width: 1040px) {
        @include span-columns(6);
        @include omega(2n);
      }

      border-top: 95px solid #F2F4F5;
      background: $white;
      position: relative;
      margin-bottom: 25px;

      .meta {
        position: absolute;
        top: -100px;
        right: 10px;
        width: 300px;
        font-size: 13px;
        list-style-type: none;
        text-align: right;
        font-weight: 400;

        .label {
          font-weight: 300;
        }
      }

      .image {
        float: left;
        width: 75px;
        height: 75px;
        border-radius: 75px;
        overflow: hidden;
        position: absolute;
        top: -85px;
        left: 25px;

        img {
          height: auto;
        }
      }

      .inner {
        padding: 20px 40px;
      }

      .tutor-link {
        font-size: 18px;
        color: $purple-text;
        font-weight: 400;
        margin-bottom: 15px;
        display: block;
      }
      dl {
        margin: 0;
        padding: 0;
        font-size: 13px;
      }
      dt {
        width: 30px;
        color: $yellow;
        font-size: 18px;
        padding-bottom: 10px;
        margin: 0;
        height: 20px;
        float: left;
      }
      dd {
        width: auto;
        float: left;
        padding-bottom: 10px;
        margin: 0;
        border: 0;
        height: 25px;
      }
    }

    .notes {
      font-size: 13px;
    }
  }

  .listing-lesson {

    background: $white;
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
    color: #07104F;

    .main-info-text {
      overflow: hidden;
    }

    .notes {
      font-size: 14px;
    }

    .date {
      position: absolute;
      top: 0;
      left: 0;
      background: #646988;
      color: $white;
      text-align: center;
      width: 55px;
      height: 55px;
      line-height: 1;
      padding: 10px 0;
      .day {
        font-size: 23px;
        font-weight: 400;
      }
      .month-year {
        font-size: 11px;
      }
    }

    .inner {
      padding: 30px 20px;
    }

    .tutor-name {
      font-weight: 400;
    }
    .match-date {
      font-size: 11px;
      text-transform: uppercase;
    }

    .time-report {
      overflow: hidden;
      & > div {
        display: inline-block;
        margin-right: 15px;
        padding-bottom: 15px;
      }

      .label {
        font-size: 11px;
        text-transform: uppercase;
      }

      .value {
        font-weight: 400;
        font-size: 15px;
      }
    }

    .councillor-note {
      .label {
        font-size: 11px;
        text-transform: uppercase;
      }
    }

    .type {
      margin-left: 55px;
      padding: 15px;
      color: #C2C3CC;
      font-weight: 400;
      float: left;
    }

    .main-info {
      clear: both;
      overflow: hidden;
      margin-bottom: 25px;
      padding-top: 15px;
    }

    img:not(.file-icon) {
      width: 38px;
      height: auto;
      border-radius: 38px;
      overflow: hidden;
      float: left;
      margin-right: 15px;
    }

  }

}

.role-student .listing-lesson button.star {
  &:hover {
    background-position: -18px 0;
  }
}

.role-tutor .listing-lesson button.star {
  cursor: default;
}

.upcoming-lessons {
  img {
    width: 60px;
    margin: 0 15px 0;
    display: inline-block;
    vertical-align: middle;
  }

  a {
    color: $purple-text;
  }
  .notes {
    font-size: 13px;
  }
}

.view-lesson-list.view-display-id-block_5,
.view-lesson-list.view-display-id-block_6,
.view-lesson-list.view-display-id-block_7,
.view-lesson-list.view-display-id-block_9 {

  overflow: hidden;

  .item {

    @include media($overmobile) {
      @include span-columns(4);
      @include omega(3n);
    }

    font-size: 15px;
    text-align: center;
    margin-bottom: 30px;
  }

  .change-communication-method {

    background: 0;
    border: 0;
    padding: 0 5px 0;
    height: 20px;
    position: relative;
    top: 3px;
    outline: none;

    svg {
      width: 16px;
      height: 16px;
    }

    path {
      fill: $grey-med;
      transition: fill 0.2s ease-in-out;
    }

    &:hover {
      path {
        fill: $purple-main;
      }
    }

  }

  .day, .time {
    line-height: 1.3;
  }
  .tutor {
    font-weight: 700;
    border-bottom: 2px dotted $grey-med;
    margin: 10px;
    padding: 10px;
    font-size: 16px;
  }

  .type {

    font-weight: 400;
    margin-top: 5px;
    text-transform: capitalize;
  }

  a.cancel {

    @extend .btn;
    color: $purple-text;
    position: relative;
    top: 20px;

  }

}

.view-match-request-list {
  background: #F7F8F9;
  margin: -25px -100px 50px -275px;
  padding: 20px 100px 20px 275px;

  .item {
    overflow: hidden;
    margin-bottom: 30px;
  }

  .match-info {
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 10px;

    & > div {
      display: inline-block;
      border-right: 1px solid $grey-med;
      padding-right: 15px;
      margin-right: 10px;

      &.created {
        border: 0;
      }

      &:last-child {
        border-right: 0;
      }

    }

  }

  .label {
    font-weight: 300;
    text-transform: uppercase;
  }

  .request {
    clear: both;
    padding-top: 15px;
  }

  .form-submit {
    margin-right: 10px;
    display: inline-block;
  }

  &.no-results {
    display: none;
  }
  .availability-preview-link {
    padding: 5px 15px;
    margin: 15px 0;
    font-size: 12px;
  }

}

.page-lesson-submit-report {
  #main {
    //height: calc(100vh - 414px);
  }
}

.view-all-wrap {
  text-align: right;
  position: relative;
  top: -77px;

  @include media($overtablet) {
    padding: 0 75px;
    margin: 0 auto;
    position: relative;
  }
  @include media($tablet) {
    padding: 0 40px;
  }

}

.view-all {
  background: none;
  border: 0;
  color: $purple-text;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
}

.view-all-title {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.profile-right .tracking {

  font-size: 14px;
  position: relative;
  padding-bottom: 60px;

  p {
    text-align: center;
    margin: 0 0 30px;
    font-size: 16px;
  }

  h4 {
    margin: 0 0 15px;
    padding: 0;
    text-transform: uppercase;
    color: #646988;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0;
  }
  .document:not(.active) {
    display: none;
  }

  a {
    color: $purple-text;
    text-transform: uppercase;
    font-weight: 400;
    text-decoration: underline;
  }

  .document-link {

    cursor: pointer;

    &.active {
      font-weight: 700;
    }

  }
  .link {
    padding: 8px 0;
    font-size: 13px;
  }
  .documents {
    width: 75%;
    float: left;
  }
  .last-upload {
    width: 40%;
    float: left;
    overflow: hidden;
  }
  .todo {
    width: 30%;
    float: left;
  }

  .history {
    width: 20%;
    float: right;

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      font-size: 13px;
    }
    li {
      padding: 3px 0;
    }
  }
  .upload-new {
    position: absolute;
    bottom: 25px;
    left: 35px;
    color: #646988;
    font-size: 12px;
  }
}

.view-student-tracking-log,
.view-cv-log,
.view-college-essays,
.view-supplementary {
  .view-empty {

    padding: 0 !important;

    & > h3 {
      display: none;
    }

  }
}

.document-upload {
  .form-item-document label {
    display: none;
  }

  .form-type-managed-file label {
    display: none;
  }
  select {
    width: 70%;
  }

}

.profile-welcome-box {
  border: 1px solid $purple-text;
  text-align: center;
  padding: 80px 40px;
  max-width: 743px;
  margin: 0 auto;

  h1 {
    margin-bottom: 0;
    font-size: 25px;
  }

  h2 {
    color: #ACB5BD;
    font-size: 15px;
    margin: 5px 0 30px;
  }

}

#user-profile-form {
  padding-top: 40px;
  @include outer-container();
  .field-name-field-user-type,
  .field-name-field-counsellor-id,
  .field-name-field-first-name,
  .field-name-field-last-name,
  #edit-timezone {
    display: none;
  }

  & > div {
    max-width: 800px;
    margin: 0 auto;
  }
  .form-submit {
    width: 250px;
    margin: 50px auto;
    display: block;
  }

}

.change-pass-link {
  display: block;

  margin: 0 auto 20px;
  text-align: right;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;

  @include media($overmobile) {
    width: 780px;
  }

  @include media($mobile) {
    text-align: center;
  }

}

#edit-account {
  label {
    width: 100%;
    float: none;
  }
  .description {
    display: none;

    &.password-suggestions {
      position: relative;
      top: 0;
    }
  }
  input {
    width: 100%;
  }

  @include media($tablet) {
    padding: 0 25px;

    .password-strength {
      display: none;
    }

    div.password-confirm {
      display: none;
    }
    .form-type-password {
      padding-right: 0;
    }
  }

}

.confirm-parent, .password-parent {
  position: relative;
  margin: 0;
  padding-right: 332px;
  width: auto;
  clear: both;

  .password-strength, .password-confirm:not(.form-text) {
    position: absolute;
    right: 0;
  }
}

div.form-item div.password-suggestions {
  margin-top: 20px;
}

.submit-report {
  #edit-submit {
    width: 150px;
    display: block;
    margin: 50px auto 0;
  }
}

.availability-notice {
  text-align: center;
}

#burger, #burger-close {
  width: 40px;
  height: 30px;
  position: fixed;
  top: 43px;
  right: 5px;
  z-index: 50;

  @include media($overmobile) {
    display: none !important;
  }

}

#burger {
  .line, &:before, &:after {
    position: absolute;
    right: 5px;
    left: 5px;
    height: 5px;
    background: $purple-text;
    display: block;

  }
  &:before, &:after {
    content: '';
  }

  .line {
    top: 5px;
  }
  &:before {
    top: 15px;
  }
  &:after {
    top: 25px;
  }
}

#burger-close {
  color: $white;
  font-size: 25px;
  text-align: center;
  font-weight: bold;
}

.error-page {
  min-height: calc(100vh - 493px);
  text-align: center;

  h1 {
    text-transform: uppercase;
    margin: 50px 0;
  }
}

#rhm-lesson-booking-form {
  .description {
    display: none;
  }

  .form-radio {
    float: left;
    margin-left: 5px;
  }
  .form-type-radios {
    .form-item {
      overflow: hidden;
    }
  }
  label[for="edit-matching"] {
    width: 100px;
  }
  label.option {
    width: auto;
    overflow: hidden;
    clear: none;
    position: relative;
    top: -5px;
    padding-left: 5px;

    .notes {
      font-size: 12px;
    }
  }
}

.group-hidden-from-student {
  .form-item {
    display: none;
  }
  .field-name-field-counsellor-note, .field-name-field-councillor-notes-internal-, .field-name-field-counsellor-id {
    display: block;

    .form-item {
      display: block;
    }

  }
}

#rhm-aptitude-test-form {

  fieldset {
    margin: 0;
    padding: 0;
  }

  .form-item:not(.form-type-textarea) {
    float: left;

    input {
      width: 100%;
    }

    &:first-child {
      width: 57%;
    }
    &:nth-child(2) {
      width: 18%;
      padding: 0 15px;
    }
    &.form-type-managed-file {
      width: 25%;
    }
  }

  .form-managed-file {

    font-size: 13px;
    position: relative;
    top: -8px;

    .form-file {
      width: 50%;
    }

    .form-submit {
      width: 100px;
    }

    .file {
      display: inline-block;
    }
    input[value=' Remove '] {
      background: #FFDCDC;
    }
  }
  #edit-submit {
    width: 240px;
    margin: 50px auto 0;
    display: block;
  }
}

.booking-error {
  color: red;
}

#lesson-communication-wrap {
  select {
    width: 70%;
  }
}

.lesson-add {
  margin-top: 15px;
  float: right;
}

.page-profile-availability-preview {

  #header, #footer {
    display: none;
  }
  #main {
    padding: 0 30px 30px;
    margin: 0;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 0;
  }

  p {
    margin: 5px 0 10px;
    font-size: 13px;
  }
  #booking-calendar {
    margin-top: -20px;
  }
  .fc-left {
    position: relative;
    top: 20px;
  }

}

.fc-time-grid-event.fc-short .fc-time{
  span{
    display: block;
  }
  &:before{
    display: none;
  }
}

.lesson-list-table {

  font-size: 14px;
  margin-bottom: 40px;
  width: 100%;

  th {
    font-weight: 700;
  }

  tr:nth-child(4n - 1) {
    background: $white;
  }
  tr:nth-child(4n) {
    background: $white;
  }

  td, th {
    padding: 10px;

    &.rating {
      padding-bottom: 0;
      text-align: right;

      label {
        width: auto;
        float: right;
        color: #C2C3CC;
        position: relative;
        text-transform: uppercase;
        font-size: 11px;
        margin-right: 15px;
        font-weight: 300;
        top: 8px;
        right: -3px;
      }
    }
  }

  .lesson-list-time {
    text-align: center;
    line-height: 1.3;
    width: 120px;
  }
  .lesson-list-name {
    min-width: 90px;
    font-weight: 600;
  }
  .form-submit {
    font-size: 11px;
    padding: 5px 15px;
  }

  .stars {
    float: right;
    position: relative;
    top: 8px;
  }
  .stars-title {
    color: #C2C3CC;
    text-transform: uppercase;
    font-size: 11px;
    margin-right: 15px;
    float: right;
    position: relative;
    top: 9px;
    right: -3px;

    @include media($mobile) {
      display: none;
    }

  }
  .star {
    float: right;
    margin: 0 3px;
  }
  button.star {
    background: url('../../images/build/star.png') 0 0 no-repeat;
    border: 0;
    color: transparent;
    width: 18px;
    height: 19px;
    display: block;
    margin-bottom: 15px;
    overflow: hidden;
    font-size: 0;
    padding: 0;

    &.active {
      background-position: -18px 0;
    }

    &:focus {
      outline: none;
    }

  }

}

.profile-tutor-availability, .profile-student-availability {

  .match-select-wrap {
    margin-bottom: 35px;
  }

}

.matched-tutors-search-wrapper {

  label {
    width: 30%;
    float: left;
    clear: left;
  }

  input[type='text'] {
    width: 30%;
    clear: left;
    display: block;
    height: 30px;
    padding: 10px 15px;
    line-height: 0;
    margin-right: 10px;
    float: left;
  }

  input[type='submit'] {
    height: 30px;
    padding: 10px 15px;
    display: inline-block;
    line-height: 0;
    margin-right: 10px;
    position: relative;
    top: 25px;
    margin-left: 5px;
  }
}

.page-profile div.messages {

  @include media($overtablet) {
    left: 400px;
  }

}

.my-notifications{
  .my-notification{
    background: #fff;
    padding: 23px 29px;
    margin-bottom: 16px;

  }
  .top{
    position: relative;
  }
  .my-notification-toggle{
    width: 22px;
    height: 22px;
    border: 0;
    padding: 0;
    background: url(../../icons/uEA0E-arrow-right.svg) no-repeat center center #FAC800;
    background-size: 12px;
    border-radius: 20px;
    transform: rotate(90deg);
    position: absolute;
    bottom: 0;
    right: 10px;
    transition: all 0.2s ease-in-out;

    &.active{
      transform: rotate(-90deg);
    }
  }
  h1{
    text-align: center;
    font-size: 20px;

    border-bottom: 2px dotted #C4C7CE;
    padding-bottom: 24px;
    margin-bottom: 30px;
  }
  .date{
    font-size: 14px;
    font-weight: 500;
    font-family: Futura;
  }
  .title{
    font-size: 14px;
    font-weight: 600;
    font-family: 'Lato', sans-serif;

  }
  .body{
    background: #F0F0F0;
    padding: 20px 18px;
    font-size: 14px;
    display: none;
    margin-top: 20px;

    p{
      margin: 0;
    }
    strong{
      font-weight: 600;
    }
  }

  .pager{
    margin-top: 40px;
  }

.notification-availability{
  .student-name{
    text-align: center;
    margin-bottom: 32px;
    font-size: 15px;
  }
  .event-blocks{
    display: flex;
    justify-content: center;
    gap: 50px;
    text-align: center;

    @include media($tablet) {
      display: block;
    }
  }
  .event-block-title{
    font-weight: 700;
    border-bottom: 2px dotted #C4C7CE;
    padding-bottom: 11px;
    margin-bottom: 11px;
  }
  .event-block-event{
    margin-bottom: 20px;
  }

}

}
