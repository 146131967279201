.node-type-page {

  #main {
    padding-top: 45px;
    min-height: calc(100vh - 463px);
    margin-bottom: 50px;

    @include media($max-width-fluid) {
      padding-left: 20px;
      padding-right: 20px;
    }

  }

}

.under-maintenance {
  min-height: calc(100vh - 371px);
  text-align: center;
  padding-top: 50px;
  padding-left: 30px;
  padding-right: 30px;
}