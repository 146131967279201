.fc-event {

  background: #07104F;
  border-color: #07104F;

  .fc-bg {
    background: none;
  }

  .fc-title {
    display: none;
  }
  .fc-content {
    position: absolute;
    bottom: 5px;
    left: 5px;
    right: 5px;
    top: 0;
    line-height: 1.2;
    font-size: 11px;
    text-transform: capitalize;
  }

  &:not(.not_available), &:not(.lesson) {

    .fc-time span {
      &:after {
        content: '\A Available';
        white-space: pre;
      }
    }
  }

  &.lesson {
    background: $yellow;
    color: $purple-text;
    border-color: $yellow;
  }

  &.not_available {
    background: #cfcfcf;
    border-color: #999;
    color: #333;

    .fc-time span {
      &:after {
        content: '\A Unavailable';
        white-space: pre;
      }
    }
  }

  &.less-preferred {

    background: #4a5079;
    border-color: #4a5079;

  }

  &.unsaved {

    &:after{
      content: '\A Unsaved';
      position: absolute;
      right: 5px;
      bottom: 5px;
      background: rgba(255,255,255,0.8);
      color: tomato;
      border: 1px solid tomato;
      font-size: 11px;
      text-align: center;
      line-height: 1.2;
      padding: 3px 6px;
      border-radius: 3px;
    }
  }

}

.has-events-wrap {
  overflow: hidden;
  padding-left: 41px;
  padding-right: 13px;
  position: relative;
  top: 10px;

  .has-events-item {
    float: left;
    width: calc(100% / 7);
    height: 10px;
    margin: 0;
    border-left: 4px solid #F7F8F9;
    border-right: 4px solid #F7F8F9;

    &.has-events {
      background: $purple-text;
    }
  }
}

.fc-view-container {
  font-size: 12px;

}

.fc {
  button {
    border: 2px solid #040A3D;
    background: none;
    color: $purple-text;
    font-weight: 700;
    text-transform: uppercase;

    &.fc-next-button, &.fc-prev-button {
      width: 30px;
      height: 30px;
      border-radius: 30px;

    }

    &.fc-today-button {
      border-radius: 0;
      font-size: 14px;
      top: 2px;
    }

    .fc-icon {
      top: -1px;
      left: -3px;
      margin: 0;
    }
    &.fc-prev-button {

      left: -5px;

      .fc-icon {
        left: -5px;
      }
    }
  }
  .fc-left h2 {
    font-size: 16px;
  }
  .fc-right {
    position: relative;
    top: -8px;
  }
}

.timezone-warning {
  font-weight: bold;
  margin-bottom: 25px;
  background: $white;
  padding: 10px 25px;
  border: 2px dotted #C4C7CE;
  text-align: center;
  p {
    margin: 0;
  }

  &.tutor-warning {

    margin-top: 20px;
    clear: both;
  }

}

.fc-time-grid .fc-slats td {
  height: 12px;
}

.fc-ltr .fc-axis {
  position: relative;

  span {
    position: absolute;
    left: 5px;
    top: 4px;
  }

}

.fc-time-grid .fc-slats .fc-minor .fc-axis {
  border-top: 0;
}

.fc-title {
  display: none !important;
}

.fc-time:after {
  display: none;
}

.match-select-actions{
  display: flex;
  gap: 60px;
  align-items: center;

  p{
    background: #F9F9F9;
    padding: 10px;
    line-height: 1;
    font-weight: 500;
    flex-grow: 1;
    font-family: 'Futura', 'sans-serif';
  }
}
.match-select-buttons{
  margin-left: auto;
  display: flex;
  gap: 14px;

  button{
    font-family: Avenir;
    font-size: 12px;
    text-transform: uppercase;
    padding: 9px 18px;
    font-weight: 700;
    line-height: 1;
  }
  .match-select-reset{
    color: $purple-text;
    border: 1px solid $purple-text;
    background: #fff;
  }
  .match-select-save{
    background: $purple-text;
    color: #fff;
    border: 0;
  }
}
