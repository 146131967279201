
.container {
  @include outer-container();
}

img {
  max-width: 100%;
  image-rendering: optimizeSpeed; /*                     */
  image-rendering: -moz-crisp-edges; /* Firefox             */
  image-rendering: -o-crisp-edges; /* Opera               */
  //image-rendering: -webkit-optimize-contrast; /* Chrome (and Safari) */
  image-rendering: optimize-contrast; /* CSS3 Proposed       */
  -ms-interpolation-mode: nearest-neighbor; /* IE8+                */
}

#main {

  @include media($overmobile) {
    margin-top: 132px;
  }

  padding-top: 30px;
}

div.messages {
  position: fixed;
  top: 127px;
  width: 100%;
  z-index: 500;

  .close {
    float: right;
    color: red;
    font-weight: bold;
  }
}

.hidden {
  display: none;
}

.fancybox-wrap {
  border: 5px solid $purple-main;
  border-radius: 0;
}

.fancybox-inner {
  min-width: 300px;
}

.fancybox-skin {
  border-radius: 0;
  background: $white;
  padding: 20px 25px 35px !important;
}

.fancybox-inner button {
  background: $purple-btn;
  color: $white;
  border: 0;
  margin-right: 10px;
  display: inline-block;
  padding: 7px 18px 9px;
  line-height: 1;
  transition: background 0.2s ease-in-out;

  &:hover {
    background: $purple-dark;
  }
}

.cancel-lesson-modal {
  text-align: center;
}