/* ----- PUT ALL YOUR AMAZING VARIABLES IN HERE ----- */

/* ----- COLORS ----- */
//-- colors:start --//
$white: #fff;
$purple-dark: #030830;
$purple-text: #0A1354;
$purple-grey-text: #021A33;
$purple-main: #060D40;
$purple-light: #8189C1;
$purple-btn: #060D40;
$yellow: #FAC800;
$yellow-light: #FFD500;
$grey-med: #ACB5BD;
$grey-heading: #637282;
$bg-grey-light: #F2F4F5;
//-- colors:end --//


//-- typo:start --//
$font-proxima: 'Neue Helvetica W01', helvetica, sans-serif; // 300, 700
$font-proxima-alternative: 'Neue Helvetica W01', helvetica, sans-serif; // 400, 400 italic
$font-newsgothic: 'Neue Helvetica W01', helvetica, sans-serif; // 700
//-- typo:end --//