.page-user.not-logged-in {

  #page {
    background: url(../../images/build/bg-login.jpg) center center no-repeat;
    background-size: cover;
    height: calc(100vh - 132px);
  }
  label {
    display: none;
  }
}

#user-login, #user-pass, #user-pass-reset {
  text-align: center;
  color: #C2C3CC;
  padding-top: 160px;
  position: relative;
  width: 300px;
  margin: 0 auto;

  h1 {
    color: $white;
  }

  &:before {
    content: '';
    border-top: 1px solid $white;
    height: 1px;
    position: absolute;
    top: 120px;
    left: 50%;
    width: 30px;
    margin-left: -15px;
  }

  &:after {
    content: '';
    border-left: 1px solid $white;
    height: 200px;
    position: absolute;
    top: -80px;
    left: 50%;
  }

  input[type="text"], input[type="password"] {
    width: 300px;
    border: 1px solid #C2C3CC;
    padding: 20px 30px;
    color: $purple-text;
  }

  .password-link {
    text-align: right;
    margin-top: -9px;

    a {
      color: #C2C3CC;
      text-transform: uppercase;
      font-size: 11px;
    }
  }

  .form-submit {
    border-color: $yellow;
    color: $yellow;
    padding: 15px 35px;

  }
  .contact-link {
    font-size: 13px;
    text-transform: uppercase;
    padding: 30px 0;

    a {
      font-weight: 700;
    }
  }
  ::-webkit-input-placeholder {
    color: #C2C3CC;
    font-style: normal;
    text-transform: lowercase;
  }

  :-moz-placeholder { /* Firefox 18- */
    color: #C2C3CC;
    font-style: normal;
    text-transform: lowercase;
  }

  ::-moz-placeholder { /* Firefox 19+ */
    color: #C2C3CC;
    font-style: normal;
    text-transform: lowercase;
  }

  :-ms-input-placeholder {
    color: #C2C3CC;
    font-style: normal;
    text-transform: lowercase;
  }

  @media(max-height: 800px) and (min-width: 480px) {
    position: absolute;
    left: 50%;
    margin-left: -150px;
    bottom: 0;

    input[type="text"], input[type="password"] {
      padding: 10px 30px;
    }
  }

}

#user-pass-reset {
  width: 400px;
}