.btn {

  background: 0;
  border: 0;
  color: $yellow;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;

}

.form-submit {

  border: 1px solid #646988;
  color: #646988;
  background: none;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 13px;
  padding: 10px 25px;
  @include bre-transition();

  &:hover {
    color: $purple-text;
    background: $white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

}

.arrow-link {

  text-transform: uppercase;
  color: $purple-text;
  font-weight: 700;
  font-size: 14px;

  &:after {
    content: '';
    width: 16px;
    height: 16px;
    background: $yellow;
    border-radius: 15px;
    font-size: 8px;
    display: inline-block;
    line-height: 1.9;
    position: relative;
    top: 2px;
    margin-left: 5px;
    padding-left: 1px;
    text-align: center;
    padding: 1px 0 0 1px;
    left: 1px;
    @include bre-transition();
    background: url(../../icons/uEA0E-arrow-right.svg) center center no-repeat $yellow;
    background-size: 8px;
  }

  &:hover {
    color: $purple-text;
    &:after {

      left: 8px;
    }
  }
}

.edit-link {
  display: inline-block;
  padding-right: 20px;
  background: url(../../images/build/pencil.png) center right no-repeat;
  color: $purple-text;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;

  &:hover {
    color: $purple-light;
  }
}

.attach-link {

  color: $purple-text;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: underline;
  font-size: 12px;
  position: absolute;
  bottom: 35px;
  left: 35px;
  color: #646988;
  font-size: 12px;

  img {
    height: 20px;
    display: inline-block;
    margin-right: 5px;
    position: relative;
    top: 5px;
  }
}

.back-link {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  position: relative;
  top: 50px;
}
