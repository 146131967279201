@font-face {
  font-family: 'Futura';
  src: url('../../fonts/FuturaStd-Book.otf') format("opentype");

}

@font-face {
  font-family: 'FuturaItalic';
  src: url("../../fonts/FuturaStd-BookOblique.otf") format("opentype");

}

body{
  font-family: 'Lato', sans-serif;
  line-height: 1.6;
  font-weight: 300;
  font-size: 16px;
  color: $purple-text;
}
h1,h2,h3,h4,h5,h6{
  font-weight: 400;
  font-family: 'Futura', 'sans-serif';
  line-height: 1.2;
}
a{
  text-decoration: none;
  color: $yellow;
  @include bre-transition();

  &:hover{
    color: $yellow-light;
  }

}
strong{
  font-weight: 400;
}
.anchor{
  padding-top: 170px;
  margin-top: -170px;
  display: inline-block;
}