.main-header{
  height: 95px;
  padding: 30px 0;
  background: $white;
  position: fixed;
  top: 38px;
  width: 100%;
  z-index: 50;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);

  @include media($mobile){
    height: 0;
    padding: 0;
  }
}

#logo{
  width: 110px;
  display: block;
  margin: 0 auto;
  @include media($mobile){
    position: fixed;
    top: 3px;
    left: 3px;
    background: $white;
    padding: 7px 15px;
    height: 33px;
    width: auto;

    img{
      width: auto;
      height: 20px;
    }
  }
}

.log-link{
  color: $purple-text;
  position: absolute;
  right: 25px;
  top: 30px;
  font-weight: bold;
}
#top-nav{
  background: $purple-dark;
  @include clearfix();
  text-transform: uppercase;
  font-size: 12px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 50;

  ul{
    margin: 0;
    padding: 0;
    float: right;
    list-style-type: none;
  }
  li{
    float: left;
    border-left: 1px solid $grey-med;
  }
  a{
    color: $grey-med;
    display: block;
    background: $purple-dark;
    padding: 10px 25px;

    &:hover{
      color: $white;
    }


  }

}
