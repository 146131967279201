
select, input[type="password"], input[type="text"], input[type="email"] {
  -webkit-appearance: none;
}

select {
  background: $white;
  padding: 10px 15px;
  border-color: #DADBE6;
  border-radius: 0;
  height: 46px;
}

fieldset {
  border: 0;
}

fieldset:not(.date-combo) {
  background: #F7F8F9;
  padding: 100px 75px 50px;
  margin: 0 0 30px;
  position: relative;

  @include media($tablet) {
    padding: 100px 15px;
    max-width: 100%;

    select {
      display: inline-block;
      width: 100% !important;
    }
  }

}

fieldset.date-combo {
  padding: 0;
  margin: 0;

  .date-padding {
    padding: 0 !important;
  }

  legend {
    width: 30%;
    border: 0;
    padding: 0;
    margin: 0;
    top: 35px;
    font-size: 16px;
    font-weight: 700;
    float: left;
    text-align: center;

    @include media($mobile) {
      text-align: right;
      padding-right: 12px;
    }
  }
}

.field-type-text-long{
  overflow: hidden;
  .description{
    clear: both;
  }
}

.field-name-field-interview-date {
  .date-combo legend {
    text-align: left;
  }
}

.form-wrapper {
  position: relative;
}

legend {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  position: relative;
  top: 75px;
  border-bottom: 2px dotted #C4C7CE;
  padding-bottom: 40px;
  width: 100%;
  text-transform: capitalize;
}

label[for="edit-field-entry-year-und-0-value-year"] {
  display: none;
}

.date-no-float {
  margin-left: 30%;
  padding-bottom: 30px;
  width: 70%;

  * {
    width: 100% !important;
  }

  select {
    width: 100%;
  }
}

label {

  text-transform: capitalize;

  @include media($overmobile) {
    width: 30%;
    float: left;
    clear: left;

  }
}

.description {
  @include media($overmobile) {
    position: absolute;
    left: 0;
    top: 30px;
    width: 30%;
  }
}

.form-type-managed-file .description {
  display: none;
}

input[type="text"], input[type="password"] {
  width: 70%;
  background: $white;
  border: 0;
  border: 1px solid #DADBE6;
  padding: 15px;
  font-style: italic;
  margin-bottom: 15px;

  @include media($mobile) {
    width: 100%;
  }
}

.form-textarea-wrapper{
  width: 70%;
  float: left;
}

textarea {
  background: $white;
  margin-bottom: 20px !important;
}

.form-radios {
  overflow: hidden;
  margin: 20px 0;
}

.file a {
  color: $purple-text;
  font-size: 13px;
  font-weight: 400;
}

.fieldset-description {
  font-weight: 700;
  text-align: center;
}

// Let the hacking commence
.field-type-field-collection {
  padding-top: 30px;
  padding-bottom: 50px;
  border-bottom: 1px solid #DADBE6;
  position: relative;

  .description {
    @include media($overmobile) {
      position: relative;
      top: -25px;
      width: 30%;
      display: block;
    }
  }

  th label {
    width: 100%;
    float: none;
    padding-bottom: 15px;
  }

  .clearfix {
    position: absolute;
    padding: 5px;
    height: 30px;
    clear: both;
    bottom: 30px;
    width: 100%;

    input[type="submit"] {
      color: $purple-text;
      font-size: 12px;
      padding: 10px 20px;
      margin: 0;
      position: absolute;
      right: 8px;
      top: 6px;
    }
    .icon-arrow-right {
      position: absolute;
      top: 15px;
      right: 0;
      height: 15px;
      width: 15px;
      background: $yellow;
      font-size: 9px;
      border-radius: 15px;
      padding: 2px 0 0 3px
    }

  }
  td {
    position: relative;
  }
  .cross {
    position: absolute;
    right: 0;
    margin-top: 8px;
    font-weight: 700;
    font-size: 13px;
  }
  input[value="Remove"], input[value="X"] {
    color: $purple-text;
    font-size: 13px;

  }

  input[value="Remove"] {
    font-size: 13px;
  }

  input[value="X"] {
    padding-right: 0;
    text-align: right;
  }

  table {
    width: 100%;
  }

  thead {
    border: 0;
  }

  th {
    border: 0;

    &:last-child {
      display: none;
    }

  }

  tbody {
    background: none;
    border: 0;

    & > tr > td:nth-child(2) {
      padding-bottom: 0;
      padding-top: 14px;
      overflow: hidden;

      @include media($mobile) {
        padding-top: 0;
      }
    }

  }
  .field-name-field-activity-date-2 {

    @include media($overmobile) {
      .date-combo {
        width: 46%;
        float: left;
        margin-top: -25px;
      }
    }

  }
  &.field-name-field-summer-programs, &.field-name-field-internships {
    padding-bottom: 75px;
  }

  #field-scholarships-values {
    .date-combo {
      legend {
        position: relative;
        left: -20px;
      }
    }
  }

  tr {
    background: 0 !important;
    border: 0;
  }

  td.delta-order, td.field-multiple-drag {
    display: none;
  }

  .date-no-float {
    margin-left: 30%;
    padding-bottom: 30px;
    width: auto;

    .form-item {
      width: 100%;

      * {
        width: 100%;
      }
    }

    .date-padding {
      padding: 0 !important;
    }
    label {
      display: none;
    }

    .description {
      @include media($overmobile) {
        position: absolute;
        bottom: 45px;
        top: auto;
        left: 0;
      }

    }
    .form-type-textfield .description {
      display: none;
    }
  }
  .form-submit {
    @extend .btn;
    float: right;
  }

  .field-name-field-grade {
    label {
      @include media($overmobile) {
        padding-left: 45px;
        width: 100px;
      }
    }
    input[type="text"] {
      @include media($overmobile) {
        width: calc(100% - 75px);
      }
    }
  }

  .field-name-field-update, .field-name-field-to-do {
    display: none;
  }
  .field-name-field-description {
    position: relative;
    z-index: 2;
  }
  .field-name-field-activity-date-2 {

    z-index: 1;

    @include media($overmobile) {
      position: relative;

    }

    .end-date-wrapper {
      .form-item {
        position: relative;
      }

      label {
        display: block;
        position: absolute;
        left: -60px;
        bottom: 26px;
        width: 30px;

        @include media($mobile) {
          left: -32px;
        }
      }

      .date-padding {
        label {
          display: none;
        }
      }
    }

  }

}

.field-name-field-universities {

  tr {
    border-top: 1px solid #DADBE6;

    td:nth-child(2) {
      padding: 40px 0 !important;
    }

  }

  thead {
    display: none;
  }
  .form-wrapper {
    clear: both;
  }
  .form-type-textfield label {
    padding: 15px 0;
    clear: both;
  }
  .date-combo {
    legend {
      position: relative;
      top: 40px;

      @include media($mobile) {
        top: 0;
        text-align: left;
        width: 100%;
      }
    }

    @include media($mobile) {
      .container-inline-date > .form-item {
        float: right;
        margin-right: 84px;
      }
    }

  }
}

.field-name-field-current-subjects,
.field-name-field-past-subjects,
.field-name-field-official-predicted-grades {
  .field-multiple-drag {
    display: none;
  }
  th {
    display: block;
    width: 100%;
    &:last-child {
      display: none;
    }
  }
  tr, td {
    display: block;
    position: relative;
  }
  .field-type-text {

    @include media($overmobile) {
      width: 45%;
      float: left;
    }

  }
  .delta-order {
    display: none;
  }
  input[type="text"] {
    @include media($overmobile) {
      margin-top: -15px;
    }
  }
  input.form-submit {
    width: 10%;
    font-size: 13px;
    margin-top: -7px;
    padding: 2px;
    text-align: right;
    min-width: 40px;

    &:hover {
      background: none;
      opacity: 0.8;
      box-shadow: none;
    }
  }
  input.field-add-more-submit {
    padding-right: 11px;
    font-size: 13px;
    width: auto;
    margin-top: 0;
  }
}

.field-name-field-summer-programs,
.field-name-field-internships,
.field-name-field-independent-work,
.field-name-field-competitions,
.field-name-field-reading,
.field-name-field-ecas,
.field-name-field-ielts,
.field-name-field-scholarships,
.field-name-field-academic-tutoring {

  tbody > tr > td:nth-child(2) {
    padding-top: 0;
  }

  input, textarea, .date-no-float {
    margin-bottom: 0 !important;
  }

  .date-no-float {
    padding-bottom: 0 !important;
    width: auto;
  }

  label {
    display: none;
  }

  thead label {
    display: block;
    width: 100%;
  }

  .form-textarea-wrapper, .field-type-datestamp {

    @include media($overmobile) {
      width: 45%;
      float: left;
    }
  }

  .field-type-datestamp {

    @include media($overmobile) {
      width: 40%;
    }

  }

  textarea {
    height: 60px;
    position: relative;
    top: 3px;
  }

  .field-type-datestamp {
    margin-top: -18px;
    width: 50%;
    padding-left: 50px;
    input {
      width: calc(100% - 30px) !important;
    }
  }

  .field-multiple-drag {
    display: none;
  }
  .fieldset-legend {
    @include media($overmobile) {
    }

  }
  input.form-submit {
    width: 10%;
    font-size: 13px;
    position: absolute;
    right: 0;
    top: 15px;
    min-width: 40px;
  }
  input.field-add-more-submit {
    padding-right: 11px;
    font-size: 13px;
    width: auto;
    margin-top: 10px;
  }
}

.node-form {
  position: relative;

  @include media($mobile) {
    padding: 0 15px;
  }

  .tabledrag-toggle-weight-wrapper,
  input[value="Preview"],
  input[value="View changes"] {
    display: none;
  }
  input[value="Next step"], input[value="Previous step"], input[value="Save"] {
    border: none;
    font-weight: 700;
    padding: 0;
    color: $purple-text;

  }
  input[value="Next step"] {
    float: right;
  }
  input[value="Previous step"] {
    float: left;
  }
  input[value="Save"] {
    float: right;

  }
  .ajax-progress-throbber {
    position: absolute;
    bottom: 60px;
    right: 0;
  }
  .field-name-field-university-notes {
    label {
      margin-bottom: 10px;
    }
  }

  .form-actions {
    .icon-arrow-right {
      float: right;
      font-size: 9px;
      height: 15px;
      width: 15px;
      background: $yellow;
      border-radius: 15px;
      padding: 2px 0 0 3px;
      margin-left: 8px;

      &.left {
        float: left;
        transform: rotate(180deg);
        margin-left: 0;
        margin-right: 8px;
      }

    }

    @include media($tablet) {
      padding: 15px;
    }

  }

  &.step-1 {
    .form-actions {
      .icon-arrow-right.left {
        display: none;
      }
    }
  }

  &.step-4 {
    .form-actions {
      .icon-arrow-right:not(.left) {
        display: none;
      }
    }
  }

  .multipage-counter_ajax {
    @include media($tablet) {
      padding: 15px;
    }
  }

  // Edit form
  .group-hidden-from-student, .group-hidden-from-tutor {
    display: none;
  }
  .multipage-pane-title {
    font-size: 20px;

    @include media($tablet) {
      text-align: center;
      padding: 30px 0 0;
    }
  }

  #edit-actions-submit {
    @extend .form-submit;
    width: 150px;
    margin: -10px auto 0;
    float: none;
    display: block;
    position: relative;
    top: -10px;

    @include media($mobile) {
      margin-top: 50px;
    }
  }

}

.role-counsellor, .role-super-counsellor, .role-administrator {
  .group-hidden-from-student, .group-hidden-from-tutor {
    display: block;
  }
}

.field-name-field-summary {
  textarea {
    width: 70%;
  }
}

.university-help {
  margin: 0 0 25px;
}

#field-entry-year-add-more-wrapper legend {
  text-align: left;
}

.step-1, .step-2, .step-3 {
  .group-university-details {
    display: none;
  }
}

.field-name-field-availability {
  .description {
    display: block;
    position: relative;
    width: auto;
    top: -10px;
  }
}
