@font-face {
  font-family: "iconfont";
  src: url('iconfont.eot');
                                                      src: url("iconfont.ttf") format("truetype"),
       url("iconfont.eot?#iefix") format("embedded-opentype"),
       url("iconfont.woff") format("woff"),
       url("iconfont.svg#svgiconfont") format("svg");
  font-weight: normal;
  font-style: normal;
}

.icon:before {
  display: inline-block;
  font-family: "iconfont";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
  width: 1.2857142857142858em;
  text-align: center;
}

.icon-star:before { content: "\EA01"; }
.icon-skype:before { content: "\EA02"; }
.icon-envelope:before { content: "\EA03"; }
.icon-magnify:before { content: "\EA04"; }
.icon-phone:before { content: "\EA05"; }
.icon-marker:before { content: "\EA06"; }
.icon-clock:before { content: "\EA07"; }
.icon-facebook:before { content: "\EA08"; }
.icon-twitter:before { content: "\EA09"; }
.icon-instagram:before { content: "\EA0A"; }
.icon-google:before { content: "\EA0B"; }
.icon-youtube:before { content: "\EA0C"; }
.icon-linkedin:before { content: "\EA0D"; }
.icon-arrow-right:before { content: "\EA0E"; }
.icon-pencil:before { content: "\EA0F"; }
.icon-paperclip:before { content: "\EA10"; }
.icon-exchange-arrows:before { content: "\EA11"; }