.rhm-student-parent {
  form {
    margin: {
      left: auto;
      right: auto;
    };
    padding: {
      top: 50px;
      bottom: 50px;
      left: 150px;
      right: 150px;
    }

    @include media($tablet) {
      padding: {
        left: 50px;
        right: 50px;
      }
    }

    max-width: 800px;
    background-color: #F7F8F9;

    .parent-reg-title {
      width: 100%;
      min-height: 1px;
      text-align: center;
      margin-bottom: 50px;

      label {
        font-size: 1.2em;
        width: 100%;
      }
      .description {
        width: 100%;
      }

      border-bottom: dashed 1px grey;
      margin: {
        left: auto;
        right: auto;
      };
    }

    input[type="text"],input[type="password"] {
      background: transparent;
      border: none;
      border-bottom: 1px solid #DADBE6;

      &:disabled {
        border-bottom: none;
      }
    }

    .form-submit {
      display: block;
      margin: {
        left: auto;
        right: auto;
      };
    }

  }

  .description {
    position: inherit;
  }
}

.rhm-student-parent-masquerade-form {
  margin: {
    left: auto;
    right: auto;
  };

  max-width: 800px;
  background-color: #F7F8F9;

  .heading {
    text-align: center;
    width: 80%;
    margin: {
      left: auto;
      right: auto;
    };
    border-bottom: dashed 1px black;
    padding: 30px;
  }

  .rhm_student_parent_switch_links {
    padding: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .rhm_student_parent_switch_link {
      display: inline-block;
      text-align: center;
      margin: 20px;
    }
  }

}
