#footer{
  position: relative;
  z-index: 500;
}
.footer-top{
  background: $purple-dark;
  color: #5F647A;
  font-weight: 400;
  padding: 30px 0;
  font-size: 15px;

  @include media($tablet){
    padding: 30px;
    .block{
      margin-bottom: 30px;
    }
  }

  .block{
    position: relative;


    @include media($overtablet){
      @include span-columns(4);

      &:nth-child(2){
        text-align: center;
      }

      &:last-child{
        text-align: right;
      }

    }

    p{
      margin: 0;
    }

    .block-inner{
      width: 190px;
      display: inline-block;
      position: relative;
      padding-left: 40px;
    }
    .block-inner:before{
      position: absolute;
      left: 0;
      top: 0;
      font-size: 20px;
    }

    &:first-child{
      .block-inner{
        width: auto;
      }
      .block-inner:before{
        content: '\EA06';
        font-family: "iconfont";
      }
    }
    &:nth-child(2){
      .block-inner:before{
        content: '\EA05';
        font-family: "iconfont";
      }
    }
    &:last-child{
      font-weight: 700;
      .block-inner:before{
        content: '\EA07';
        font-family: "iconfont";
      }
    }
  }

}

.footer-bottom{
  overflow: hidden;
  @include media($overtablet) {
    background: linear-gradient(90deg, #E1E2F0 50%, #D1D2E3 50%);
  }
  @include media($tablet) {
    background: #E1E2F0;
    text-align: center;
  }

  padding: 30px 0;
  position: relative;

  &:after{
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 32px 32px 32px;
    border-color: transparent transparent $yellow transparent;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -32px;
  }

  .footer-menu{

    @include media($overtablet) {
      @include span-columns(6);
    }
    ul{
      list-style-type: none;
      margin: 0 0 20px;
      padding: 0;

      @include media($overtablet) {
        column-count: 2;
      }
    }
    a{
      color: #5E6D7D;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;

      &:hover{
        color: #222;
      }
    }

  }



  .share{

    text-align: center;
    font-size: 22px;

    @include media($overtablet) {
      @include span-columns(6);
    }
    @include media($tablet) {
      background: #D1D2E3;
      padding: 30px 15px;

    }
    ul{
      list-style-type: none;
      display: inline-block;
      margin: 0;
      padding: 0;
      margin-top: 10px;
    }
    li{
      float: left;
      margin: 0 13px;
    }
    a{
      color: #8286A8;

      &:hover{
        color: #333;
      }
    }

    .copyright{
      display: inline-block;
      font-size: 12px;
      text-transform: uppercase;
      color: #8286A8;
      font-weight: 700;
      position: relative;
      top: 10px;
    }

  }


}
